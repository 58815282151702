<template>
  <div>
    <b-link
      id="show-btn click"
      @click="$bvModal.show('uploadPrices')"
    >
      Subir característica mediante CSV
    </b-link>
    <b-modal
      id="uploadPrices"
      :hide-footer="true"
      size="lg"
    >
      <validation-observer ref="uploadPrices">
        <b-card-title
          title-tag="h1"
          class="font-weight-bolder mb-1 text-center"
        >
          Subir característica mediante CSV
        </b-card-title>
        <div class="asignar-trabajos-modal">
          <b-row
            class="justify-content-center middle"
            align-v="center"
          >
            <b-col
              class="text-center"
              lg="10"
            >
              <span>Debe utilizar esta plantilla o no se subirán los precios correctamente: </span>
              <a
                href="/csv/PlantillaCalculadora.csv"
                download
              >
                Plantilla
              </a>

            </b-col>
          </b-row>

          <b-form
            class="mt-2"
            @submit.prevent="handleSubmitUploadPrices"
            @keydown.enter="handleSubmitUploadPrices"
          >
            <b-container class="pl-3 pr-3">
              <b-row
                class="justify-content-center middle"
                align-v="center"
              >
                <b-col lg="8">
                  <validation-provider
                    #default="{ errors }"
                    :name="'tipo'"
                    rules="required"
                  >
                    <b-form-group
                      :label="'Tipo'"
                      label-for="upload-tipo"
                    >
                      <v-select
                        v-model="tipoUpload"
                        :required="!tipoUpload"
                        label="name"
                        :options="selectTipoUpload"
                        :filterable="false"
                        :searchable="false"
                      />
                    </b-form-group>
                    <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <b-form-group
                      :label="'Serie'"
                      label-for="upload-serie"
                      v-if="tipoUpload && tipoUpload.value == 'precios'"
                  >
                    <v-select
                        v-model="serieUpload"
                        label="name"
                        :options="selectSerie"
                        :filterable="false"
                        :searchable="false"
                    />
                  </b-form-group>
                  <b-button
                    v-if="tipoUpload"
                    variant="primary"
                    size="sm"
                    class="mr-1 mb-1 text-nowrap"
                    @click="download()"
                  >
                    Descargar datos actuales
                  </b-button>
                  <!--TODO: como hace requerido un csv?-->
                  <b-form-group
                    :label="'CSV'"
                    label-for="csv"
                  >
                    <ImageDropzone
                      ref="excel"
                      max-files="1"
                    />
                  </b-form-group>

                </b-col>
              </b-row>
              <b-row class="text-center mb-3">
                <b-col>
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mt-1 mr-1 text-uppercase"
                  >
                    {{ 'Actualizar' }}
                  </b-button>
                </b-col>
              </b-row>
            </b-container>
          </b-form>
        </div>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'
import vSelect from 'vue-select'
import { config } from '@/shared/app.config'
import axios from 'axios'
import {
  BButton,
  BCardTitle,
  BCol,
  BContainer,
  BForm,
  BFormGroup,
  BLink,
  BRow,
} from 'bootstrap-vue'

export default {
  name: 'UploadCSV',
  components: {
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    ImageDropzone,
    BLink,
    BCardTitle,
    BContainer,
  },
  props: {
    idProduct: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      excel: '',
      tipoUpload: '',
      serieUpload: '',
      selectTipoUpload: [
        {
          name: 'Precios',
          value: 'precios',
        },
        {
          name: 'Modelo cajón',
          value: 'modelo_cajon',
        },
        {
          name: 'Modelo guias',
          value: 'modelo_guias',
        },
        {
          name: 'Modelo motor',
          value: 'modelo_motor',
        },
      ],
      selectSerie: [
        {
          name: 'sin serie',
          value: '',
        },
        {
          name: 'Serie H',
          value: 'h',
        },
        {
          name: 'Serie V',
          value: 'v',
        },
        {
          name: 'Serie VF',
          value: 'vf',
        },
      ],
    }
  },
  methods: {
    ...mapActions({
      uploadCalculator: 'steps/uploadCalculator',
    }),
    handleSubmitUploadPrices() {
      this.$refs.uploadPrices.validate().then(success => {
        if (success) {
          const formData = this.createFormDataUpload()
          console.log(formData)
          if (formData) {
            this.uploadCalculator({ calculator: formData })
          }
        } else {
          this.$refs.errorField.scrollIntoView({
            behavior: 'smooth',
          block: 'end', 
          });
        }
      })
    },
    createFormDataUpload() {
      const data = this.$refs.excel.getFormData('excel')

      data.append('product_id', this.idProduct)
      data.append('type', this.tipoUpload.value)
      data.append('serie', this.serieUpload.value)

      return data
    },
    ...mapMutations({
      loading: 'app/loading',
    }),
    download() {
      if (this.tipoUpload) {
        this.loading(true)
        let serie = this.serieUpload ? this.serieUpload.value : ''
        if (this.tipoUpload.value !== 'precios') {
          serie = ''
        }
        axios({
          url: `${config.webApiBase}/calculators/excel`, // your url
          data: {
            type: this.tipoUpload.value,
            product_id: this.idProduct,
            serie,
          },
          method: 'POST',
          responseType: 'blob', // important
        }).then(response => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data)

          // create "a" HTML element with href to file & click
          const link = document.createElement('a')
          link.href = href
          link.setAttribute('download', 'data.csv') // or any other extension

          document.body.appendChild(link)
          link.click()

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link)
          URL.revokeObjectURL(href)
          this.loading(false)
        })
      }
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
