<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0 hGoBack">
        <span @click="$router.go(-1)">
          <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
            <feather-icon icon="ArrowLeftCircleIcon" size="35" />
          </b-button>
        </span> Editar producto
      </h2>
    </div>
    <b-card>
      <!-- form -->
      <validation-observer ref="editProduct">
        <b-form
            class="mt-2"
            @submit.prevent="handleSubmit"
        >
          <b-row>
            <b-col sm="6">
              <BaseInputText
                  v-model="name"
                  label="Nombre"
                  placeholder="Nombre"
                  rules="required"
                  localize
              />
              <b-form-group
                  :label="'Categoria'"
                  label-for="category-parent"
              >
                <v-select
                    label="name"
                    :filterable="false"
                    :searchable="false"
                    :options="selectCategoriesParent"
                    v-model="parent"
                    :disabled="true"
                    :placeholder="'Categoria'"
                />
              </b-form-group>
              <b-form-group
                  :label="'Subcategoría'"
                  label-for="product-category"
              >
                <v-select
                    v-model="category"
                    label="name"
                    :options="selectCategories"
                    :filterable="false"
                    :searchable="false"
                >
                </v-select>

              </b-form-group>
              <BaseInputText
                  v-if="product.type !== 'extra'"
                  v-model="model"
                  label="Objetivo / Modelo"
                  placeholder="Modelo"
                  rules="required"
                  localize
              />
              <validation-provider
                  v-if="product.type === 'cuadro control'"
                  #default="{ errors }"
                  :name="'Amperios'"
                  rules="required"
              >
                <b-form-group
                    :label="'Amperios'"
                    label-for="product-amperios"
                >
                  <b-form-input
                      v-model="amperios"
                      name="Amperios"
                      :placeholder="'Amperios'"
                  />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <b-form-group
                :label="'Web'"
                label-for="product-url"
              >
                <BaseInputText
                  v-model="url_web"
                  name="Web"
                  :placeholder="'Web'"
                  localize
                />
              </b-form-group>
              <BaseEditor
                v-model="description"
                label="Descripcion"
                localize
              />
              <BaseEditor
                v-model="objetives"
                label="Objetivos"
                localize
              />
              <BaseEditor
                v-model="characteristics"
                label="Caracteristicas"
                localize
              />
            </b-col>
            <b-col sm="6">
              <b-form-group
                v-if="product.type === 'extra'"
                :label="'Tipo precio'"
                label-for="product-tipe-price"
              >
                <v-select v-model="tipePrice" label="name" :filterable="false" :searchable="false"
                          :options="selectTipePrice" :placeholder="'Tipo precio'" />
              </b-form-group>
              <b-form-group
                :label="'Precio'"
                label-for="product-price"
              >
                <b-form-input
                  v-model="product.price"
                  name="Precio"
                  :placeholder="'Precio'"
                  :disabled="product.type === 'cortina' || product.type === 'cortina humo' || product.type === 'cortina cortafuego'"
                />
              </b-form-group>
              <b-form-group
                v-if="product.type == 'cortina humo' || product.type == 'cortina cortafuego' || product.type == 'cortina'"
              >
                <b-form-checkbox
                  v-model="sin_tarifa"
                  switch
                  inline
                >
                  <span>Producto sin tarifa</span>
                </b-form-checkbox>
              </b-form-group>

              <b-form-group
                :label="'Imagen'"
                label-for="product-image"
              >
                <ImageDropzone
                  v-if="productPrepare"
                  ref="imagen"
                  :files="imagen"
                  max-files="1"
                />
              </b-form-group>
              <b-form-group
                :label="'Documentos'"
                label-for="product-documents"
              >
                <ImageDropzone
                  v-if="productPrepare"
                  ref="documents"
                  :files="files"
                  max-files="10"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="product.type == 'cortina humo' || product.type == 'cortina cortafuego' || product.type == 'cortina'"
              sm="12"
            >
              <b-form-group>
                <b-form-checkbox
                  v-model="calculator"
                  :checked="calculator"
                  switch
                  inline
                >
                  <span>Mostrar en el listado del tarificador</span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              v-if="calculator"
              sm="12"
            >
              <UploadCSV :id-product="product.id" />
            </b-col>

            <b-col cols="12 text-right">
              <b-button
                type="submit"
                variant="primary"
                class="mt-2 mr-1 text-uppercase"
              >
                {{ 'Guardar' }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import FormService from '@/shared/service/form-service'
import { mapActions, mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'
import vSelect from 'vue-select'
import BaseInputText from '@/components/ui/input/BaseInputText.vue'
import BaseEditor from '@/components/ui/editor/BaseEditor.vue'
import { config } from '@/shared/app.config'
import UploadCSV from './Upload.vue'

export default {
  components: {
    BaseEditor,
    BaseInputText,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    ImageDropzone,
    UploadCSV,
  },
  data() {
    return {
      roles: '',
      category: '',
      files: [],
      name: {},
      description: {},
      objetives: {},
      characteristics: {},
      model: {},
      imagen: [],
      url_web: [],
      required,
      email,
      productPrepare: false,
      isDisabled: false,
      items: [],
      calculator: 0,
      sin_tarifa: 0,
      amperios: '',
      tipePrice: '',
      selectTipePrice: config.tipePriceSelect,
      parent: {},
    }
  },
  computed: {
    ...mapGetters({
      selectRoles: 'users/getRoles',
      product: 'products/getProduct',
      selectCategories: 'categories/getSelectCategories',
      selectCategoriesParent: 'categories/getSelectCategories',
    }),
  },
  async created() {
    await this.getRoles()
    await this.getProduct(this.$route.params.id)
    await this.getSelectCategoriesParent({ parent: 0 })
    await this.setData()
  },
  methods: {
    ...mapActions({
      edit: 'products/edit',
      getProduct: 'products/getProduct',
      getSelectCategories: 'categories/getSelectCategories',
      getSelectCategoriesParent: 'categories/getSelectCategories',
      uploadCalculator: 'products/uploadCalculator',
      getRoles: 'users/getRoles',
      currentLanguage: 'languages/getCurrentLanguage',
    }),
    async handleSubmit() {
      const valid = await this.$refs.editProduct.validate()
      if (!valid) {
        this.$refs.errorField.scrollIntoView({
          behavior: 'smooth',
        block: 'end',
        });
        return
      }

      this.edit({ id: this.product.id, product: this.createFormData() })
    },
    setData() {
      if (!this.product) {
        return
      }

      this.name = this.product.translations.name
      this.model = this.product.translations.modelo
      this.description = this.product.translations.description
      this.objetives = this.product.translations.objetives
      this.characteristics = this.product.translations.characteristics
      this.url_web = this.product.translations.url_web
      this.category = this.product?.category || ''
      this.calculator = this.product?.calculator === 1
      this.sin_tarifa = this.product?.sin_tarifa === 1
      this.amperios = this.product?.amperios || ''
      this.selectTipePrice.filter(item => {
        if (item.value == this.product.tipe_price) {
          this.tipePrice = item
        }
      })
      this.parent = this.product.category_parent.name[this.currentLanguage]
      this.getSelectCategories(this.product.category_parent.id)
      if (this.product.image && this.product.image.length > 0) {
        const aux = this
        this.product.image.forEach(element => {
          const file = {
            name: element.name,
            url: element.path,
            urlDelete: `/products/delete_image/${aux.product.id}/${element.id}`,
          }
          this.imagen.push(file)
        })
      }

      if (this.product.documents.length > 0) {
        const aux = this
        this.product.documents.forEach(element => {
          const file = {
            name: element.name,
            url: element.path,
            urlDelete: `/products/delete_doc/${aux.product.id}/${element.id}`,
          }
          this.files.push(file)
        })
      }

      this.productPrepare = true
    },
    createFormData() {
      const data = this.$refs.documents.getFormData('documents')
      this.$refs.imagen.getFormData('image', data)

      FormService.appendEntry(data, this.name, 'name')
      FormService.appendEntry(data, this.url_web, 'url_web')
      FormService.appendEntry(data, this.model, 'modelo')
      FormService.appendEntry(data, this.description, 'description')
      FormService.appendEntry(data, this.objetives, 'objetives')
      FormService.appendEntry(data, this.characteristics, 'characteristics')
      FormService.appendEntry(data, this.tipePrice.value, 'tipe_price')

      if (this.product.tipo !== 'cortina') {
        FormService.appendEntry(data, this.product.price, 'price')
      }

      FormService.appendEntry(data, this.calculator ? 1 : 0, 'calculator')
      FormService.appendEntry(data, this.sin_tarifa ? 1 : 0, 'sin_tarifa')
      FormService.appendEntry(data, this.category?.id, 'category_id')

      FormService.appendEntry(data, this.amperios, 'amperios')

      return data
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}

.icon-trash {
  padding-top: 7px;
}
</style>
